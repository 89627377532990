/******************************************************************
	Main SCSS
******************************************************************/
 
@import "global/variables";
@import "global/typography";

@import "components/header";
@import "components/page-buttons";
@import "components/tooltips";
@import "components/custom-grid.scss";
@import "components/no-data-overlay";
@import "pages/pages";



$medium: 960px;
$large: 961px;

html {
       height: 100%;

       body {
              min-height: 100%;
       }
}

.App {
       text-align: center;
       background:  #f7f8f9;
       @media screen and (min-width: $large) {
              display: flex;
              min-height: 96vh;
              align-items: stretch;


              .react-flow-content {
                     width: 100%; 
                     height: 100vh;
                  
              }
       }

       @media screen and (max-width: $medium) {
              display: flex;
              flex-flow: row wrap;
              margin-left: auto;
       }

       @media screen and (orientation:landscape) {
              .landscape-hide {
                     display:none;
              }
       }
}
