.all-vehicles-button{
       border: 1px solid #FFFFFF;
       box-sizing: border-box;
       border-radius: 3px;
       color: white !important;
       height: 40px;
       padding : 10px;
       a{
            text-decoration: none;
            border: none;
            padding: 10px
            
       }
       @media screen and (max-width: $small) {
             padding: 10px
          }
}