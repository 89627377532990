#createAnAccount {
    padding: 5%;
}
.simpleModal {
    .largeGreenButton, .contactFormSubmit {
        background-color: $light-green ;
        border: 1px white solid;
        color: white;
        padding: 10px 100px;
        border-radius: 5px;
        margin: 40px 0px 20px 0px;
    }
}


.contactBoxDesktop {
    margin-top: 200px;
    .readyforEVTitle {
        display: flex;
        justify-content: space-evenly;
        margin: 10px 10px 20px -90px;
    }
    .contactText {
        margin-left: -80px;
    }
    .contactForm {
        .largeGreenButton, .contactFormSubmit {
            background-color: $light-green ;
            border: 1px white solid;
            color: white;
            padding: 10px 100px;
            border-radius: 5px;
        }
    }
}
.contactBoxMobile {
    margin: 0px 0px 30px 0px;

    .contactBoxTitleMobile{
        display: flex;
        justify-content: flex-start;
        margin: 10px 10px 20px 30px;
    }
    .contactBoxMobileText {
        margin-left: 30px;
    }
    .contactForm {
        width: 250px;
        margin: 0px 0px 0px 25px;
        
    }
}

.create_account {
    color: white;
    margin-top: 30%;
    height: 600px;
    width: 50%;
    padding: 0px;
    background-color: $slategrey;
    border: 1px solid white;

    button {
        background-color: $light-green ;
        border: 1px white solid;
        color: white;
        padding: 10px 100px;
        border-radius: 5px;
    }
    label {
        color: darkgrey;
        background-color: $slategrey;
    }
    input {
        border-bottom: 1px solid white;
        color: white;
    }
    select {
        color: white;
    }

    #mui-component-select-usersFleetSize {
    color: white;
    border-bottom: 1px solid white;
    }
}



.commentTextArea {
    background-color: $slategrey;
    color: white;
    width: 100%;
    // border: none;
    border: 1px solid grey;
}