.vehicle-details {
    background: #333;
    padding: 100px;
    @media screen and (max-width: $small) {
        padding: 0px;
    }
    #VehicleDetailsHero{
        margin: 50px 60px
    }
    .mobile-page-title{
       
        display: none;
        @media screen and (max-width: $small) {
            display: inline;
            font-size: 24px;
            font-weight: 700;
            display: flex;
        }
    }
    .info-wrapper {
        .vehicle-info-box {
            color:white;
            min-height: 350px;
            font-size: 24px;
            p {
                text-transform: uppercase;
                font-size: 12px;
            }

            @media screen and (max-width: $small) {
                padding: 15px 25px 15px 48px;
                background: grey;
                background-color: $blackgradient;
            }
            .details-container{
                background-color: black;
                background: $blackgradient;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                height: 300px;
                text-align: left;
                padding: 25px 0px 0px 40px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                @media screen and (max-width: $small) {
                background: linear-gradient(
                    165.98deg
                    , rgba(0, 0, 0, .9) 10.84%, rgba(51, 51, 51, 0.5) 92.87%);
                }
                .label{
                    font-size: 12px;
                }
                .value{
                    font-size: 24px;
                    font-weight: 700;
                    text-transform: capitalize;
                }
        
            }
        }
        .title {
            background: rgba(255, 255, 255, 0.2);
            display: flex;
            font-size: 18px !important;
            height: 50px;
            align-items: center;
            justify-content: center;
            border-top-left-radius: 10px;
            font-family: 'DM Sans', sans-serif;
            border-top-right-radius: 10px;
            text-transform: none !important;
            font-weight: 700;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(22px);
        }
        
    }

    .vehicle-hero-image{
        background: #333;
    
        img{
            height: 300px;
            @media screen and (max-width: $small) {
                height: 150px;
                margin-top: 50px
            }
        }
    
    }

    .vehicle-hero-details {
        .details-section{
            background-color: $blackgradient;
            justify-content: center;
            border-top-left-radius: 25px;
            @media screen and (max-width: $small) {
                width: 90vw;
            }
            #msrp{
                font-size: 20px;
            }
            .page-title{
                font-size: 32px;
                font-weight: 700;
                text-align: left;
                @media screen and (max-width: $small) {
                display: none;
                }
            }
            .title{
                margin-top: 40px;
                background: rgba(255, 255, 255, 0.2);
                display: flex;
                flex-direction: column;
                font-size: 18px !important;
                height: 50px;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 10px;
                font-family: 'DM Sans', sans-serif;
                border-top-right-radius: 10px;
                text-transform: none !important;
                font-weight: 700 !important;
                font-weight: bold;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(22px);
                p{
                    font-size: 18px;
                    font-weight: 700;
                }
            
            }
            .msrp-value{
                margin-bottom: 30px;
                background-color: black;
                background: linear-gradient(165.98deg, rgba(0, 0, 0, 0.5) 10.84%, rgba(51, 51, 51, 0.5) 92.87%);
                box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                height: 100px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                display: flex;
                align-items: center;
                p{
                    font-weight: 700;
                    font-size: 40px;
                }
            }
            .page-sub-title{
                border: solid;
            }
            .vehicle-info-box {
                background: linear-gradient(165.98deg, black 10.84%, rgba(51, 51, 51, 0.5) 92.87%);
                color:white;
                height: 250px;
                font-size: 24px;
                .title {
                    background: rgba(255, 255, 255, 0.2);
                    color: white;
                    font-size: 3px;
                    height: 50px;
                    align-items: center;
                    justify-content: center;
                    justify-items: center;
                }
            }
        }
    }
}