.GMHeaderComponent{
    display: flex;
    flex-direction: column;
    height: 15vh;
    background-color: white;
    .GMHeaderLogo{ 
        height: 36px;
        margin-bottom: 5px;
    }
}

.thisHeader{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Gotham Book Italic";
    font-size: 16px;
    width: fit-content;
    color: #777777;
    margin-left: 5vw;
    margin-top: 4vh;
    background-color: white;
    .logoText{
        font-size: 12px;
        span{
            position: absolute;
        }
    }
    #logo{
        height: 15px;
        margin-left: 5px;
    }
}