@media print {
    #printModal {
        display: none;
    }
    #data_grid {
        width: 120vw !important;
    }
}

.catalog {
  .data_grid_details {
    border: "solid grey 4px";
    background: #FFFFFF;
    margin: 0;
    height: 625px;
    width: 83vw;
    font-size: 12px;
    margin-left: -25px;
  }
  .MuiDataGrid-root {
      .MuiDataGrid-cell {
            overflow: unset;
            white-space: unset;
            line-height: unset !important;
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left;
            font-size: 12px;
    }
  }
}
.printModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .PrintModalSubmit,  .PrintModalSubmit:hover {
            background-color: #007DBE;
            color: white;
        }
        .printHeadline  {
            display: flex;
            justify-content: center;
            font-weight: bold;
            color: darkgray;
            margin: 10px 0px 10px 0px;
        }
        
        #printAll, #printTab {
             display: flex;
             justify-content: center;
             
        }
        #printTab{
            margin-bottom: -35px;
        }
        .PrintModalSubmit {
            display: flex;
            border-radius: 0px;
            margin: 20px 0px 60px -20px;
            padding: 10px 20px 10px 20px;
            @media (min-width: 1200px) {
                width: 350px;
            }    
        }

        .printHeadline {
            margin: 30px 0px 0px 20px;
        }
    }

.incentive_details {
        .print_details_page {
            display: none;
        }
    .printSection {
        cursor: pointer;
    }
    .printButton, .exportButton {
        // @media (min-width: 900px) {
        //      margin-left: 1000px;
        // }
        margin-top: 2px;
        background-color: #f7f8f9;
        border: none;
        display: flex;
        align-items: center;
        color:rgb(155, 155, 155);
        font-size: 10px;
        font-weight: lighter;
        margin-right: 10px;
    }
    .exportTitle, .printTitle {
        margin-top: 15px;
    }
    .arrowBack {
        cursor: pointer;
        color: #979797;
        &:hover {
            color: black;
        }
    }
    text-align: left;
    background-color: #f7f8f9;

    .MuiTabs-indicator {
        background-color: #007DBE;
    }


    .data_grid {
            .MuiDataGrid-columnHeaderTitle, .MuiDataGrid-root {
                font-weight: bold;
            }
            
            .columnTitle {
                color: grey;
                font-weight: 600;
            }
        }
    #Catalog {
        display: flex;
        justify-content: center;
        .catalog_title {
            margin: 10px 0px 20px ;
            text-align: center;
            display: flex;
            justify-content: center;
            color: grey;
            font-style: italic;
            font-size: 14px;
            width: 100%;
        }
        
    }


    #IncentiveDetails {
        font-weight: bolder;
        width: 90%;
        margin-left: 5%;
        background-color: #f7f8f9;
        color: black;
        .Page_Title_Row {
            @media (min-width: 960px) {
            .MuiGrid-grid-md-1 {
            max-width: 5.3333%;
                }
            }
            flex-wrap: nowrap;
            margin-top: 20px;
            margin-left: 20px;
            .page_title {
                margin-top: 8px;
                font-weight: bold;
            }
            .clickable_button {
                cursor: pointer;
            }
        }
        .MuiTab-root {
            min-width: 24.2%;
            border-bottom: solid gray 1px;
        }

        .MuiPaper-elevation4 {
            box-shadow: none;
            width: 100%;
        }
        #rebateTag {
            @media (max-width: 960px) {
                max-width: 100%;
                margin: 20px 18px 20px 3%;
            }
            max-width: 92%;
            margin: 20px 30px 20px 3%;
            padding-right: 20px;
            border: 1px solid lightgray;
            background-color: white;
            .rebate_label {
                color: gray;
                font-weight: bold;
                margin-left: 20px;
                display: flex;
                border-bottom: 1px solid lightgray;
            }
            .rebate_title {
                display: flex;
                margin-left: 20px;
                margin-top: -5px;
                .rebate_title_text {
                    font-weight: bold;
                    font-size: 20px;
                }
            }
            .limitation_ammount {
                font-style: italic;
                display: flex;
                justify-content: flex-end;
                color: gray;
            }
        }            
        .catalog_details_box {
            margin: 0 2% 2% 3%;
            padding: 0;
            background-color: white;
            border: 1px solid lightgray;
            margin: 20px;
            .apply_button {
                background-color: #007DBE;
                color: white;
                text-transform: capitalize;
                width: 100%;
                border-radius: 1px;
            }
        }   
        .main-tabs {
            color: #007DBE;
            background-color: #f7f8f9;
        }
        #keyFactsCardsLeft, #keyFactsCardsMiddle, #keyFactsCardsRight, #keyFactsCardsBottom {
            margin: 0 2% 2% 3%;
            padding: 0;
            
        }          
        #keyFactsCardsRight {
            @media (min-width: 960px) {
                max-width: 32%;
            }
        }      
        .multi_item_title {
            padding-bottom: 15px;
            color: gray;
            text-transform: uppercase;
            font-weight: bolder;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid lightgray;  
            
            .titleleft, .titleright {
            font-weight: bolder;
        }
            .Closed {
                color: red;
            }
            .Open {
                color: green;
            }
            .Hiatus {
                color: orange;
            }
        }
        .catalog_details_info {
                display: flex;
                justify-content: flex-start;
                font-weight: bold;
                font-size: 20px;
            }
        .catalog_details_info_subheader {
                display: flex;
                justify-content: flex-start;
                font-size: 14px;
                font-family: "Gotham Medium";
            }
    } 
         
    .OverViewTabPage {
        border: 1px solid lightgray;
        background-color: white;
        padding: 25px;
        margin-left: -25px;
        width: 83vw;

        .mainTitle {
            font-weight: bold;
            margin-bottom: 30px;
        }

        #OverviewTabLeft {
            width: 70%;
            
            .mainsubTitle {
            font-weight: bold;
            margin-bottom: 15px;
            }
            .mainText {
                font-style: italic;
                margin-bottom: 15px;
            }
            .detailsLink {
                display: flex;
                text-decoration: none;
                font-style: normal;
            }
        }
        #OverviewTabRight {
            margin-top: 10px;
            margin-left: 30px;
            width: 25%;

            .mainsubTitle {
                font-weight: bold;
                margin-bottom: 20px;
            }
            .mainText {
                font-style: italic;
                margin-bottom: 40px;  
            }
            .mainText::first-letter {
                text-transform: uppercase;
            }
            
        }        
    }

    .EligibilityTabPage {
        width: 83vw;
        margin-left: -25px;
        .markdown {
            font-size: 1rem;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            line-height: 1.5;
        }
            .mainTitle {
                font-weight: bold;
                margin-bottom: 20px;
            }
            .mainsubTitle {
                font-weight: bold;
                margin-bottom: 20px;
            }
            .mainText {
                font-style: italic;
                margin-bottom: 40px;  
            }
            .mainText::first-letter {
                text-transform: uppercase;
            }
        #EligibilityTabTitleTop {
            margin-bottom: 30px;
            width: 140%;
            padding: 40px 20px 20px 20px;
            background-color: white;
            border: 1px solid lightgray;

            #EligibilityTabTitleTopLeft {
                display: flex;
                flex-direction: column;
                width: 35%;
            }
            #EligibilityTabTitleTopRight {
                display: flex;
                flex-direction: column;
                width: 65%;
                margin-top: 45px;
            }
        }
        #EligibilityTabTitleMiddle {
            margin-bottom: 30px;
            width: 100%;
            padding: 40px 20px 20px 20px;
            background-color: white;
            border: 1px solid lightgray;
            
            #EligibilityTabTitleMiddleLeft {
                display: flex;
                flex-direction: column;
                width: 30.333333333%;
            }
            #EligibilityTabTitleMiddleCenter {
                margin-top: 45px;
                display: flex;
                flex-direction: column;
                width: 33.333333333%;
            }
            #EligibilityTabTitleMiddleRight {
                margin-top: 45px;
                display: flex;
                flex-direction: column;
                width: 33.333333333%;
            }
        }
        #EligibilityTabTitleBottom {
            display: flex;
            width: 100%;
            padding: 40px 20px 20px 20px;
            background-color: white;
            border: 1px solid lightgray;

            #EligibilityTabTitleBottomLeft {
                display: flex;
                flex-direction: column;
                width: 50%;
            }
            #EligibilityTabTitleBottomRight {
                display: flex;
                flex-direction: column;
                margin-top: 45px;
                width: 50%;
            }
        }
    }

    .FundingTabPage {
        margin-left: -25px;
        background-color: white;
        border: 1px solid lightgray;
        padding: 40px 20px 20px 20px;
        width: 83vw;

        .mainTitle {
                font-weight: bold;
                margin-bottom: 30px;
            }
            .mainsubTitle {
                font-weight: bold;
                margin-bottom: 20px;
            }
            .mainText {
                font-style: italic;
                margin-bottom: 40px;  
            }
            .mainText::first-letter {
                text-transform: uppercase;
            }
        #FundingTabSectionTop, #FundingTabSectionTwo, #FundingTabSectionThree, #FundingTabSectionFour {
            border-bottom: 1px solid rgb(155, 155, 155);
            margin-bottom: 20px;
        }
        #FundingTabSectionTop {
            display: flex;
            

           #FundingTabSectionTopLeft {
                display: flex;
                flex-direction: column;
                width: 30%;
           }
           #FundingTabSectionTopRight {
               display: flex;
                flex-direction: column;
                width: 30%;
                margin-top: 55px;
           }
        }
    }


}



