.GmHomeImage {
  width: 100vw;
  height: 100vh;
}

.zappyride_logo {
  position: absolute;
  z-index: 100;
  right: 5%;
  height: 10vh;
}

#GmHomeImage {
  .zappyride_logo {
    position: absolute;
    z-index: 100;
    right: 5%;
    height: 10vh;
  }
  p {
    display: flex;
    justify-content: center;
    height: 22px;
    font-size: 16px;
    margin: 30px;
    font-family: "Gotham Book Italic";
    #logo {
      padding-left: 5px;
      height: 25px;
    }
  }
}

.LoginContainer {
  width: 330px;
  height: 300px;
  z-index: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#LoginButton {
  display: flex;
  align-items: center;
  line-height: 50px;
  justify-content: center;
  height: 40px;
  width: 200px;
  font-size: 14px;
  background-color: #007dbe;
  color: white;
  text-decoration: none;
  z-index: inherit;
  margin: 0 auto;
  padding: 5px 0;
}
.GMLogo {
  z-index: 100;
  height: 60px;
  margin-top: 50px;
}
@media only screen and (max-width: 375px) {
  .GmHomeImage {
    height: 100vh;
  }
  .LoginContainer {
    width: 80vw;
    height: 60vh;
    z-index: 300;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
