
.title_with_tooltip_display{
    display: flex;
    align-items: flex-end;
    height:50px;
}
svg{
    padding: 10px
}

#tooltip_container{
    background: #FFFFFF;
    box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.15);
    border-radius: px;
    border: none;

    .details-container{
        padding: 10px;
        .details {
            font-family: "Gotham Medium";
            text-transform: uppercase;
            font-size: 10px;

            .value{
                font-family: "Gotham Book Italic";
                font-size: 12px;
                text-transform: none;
                margin: -14px 0px 20px 12px;
               
            }
        }
    }
}

#icon_class{
    margin-left: 25px;
    font-family: "Gotham Medium";
    color: #777777;
    font-weight: 700;
    text-transform:none;

}
#box_needed{
    padding-bottom: 10px;

    .MuiTypography-body1{
        display: flex;
        align-items: center;
        margin:0px 0px 5px 12px;
    svg {
        padding: 10px 2px 10px 0px;
        }
    }
}
p {
    #box_needed{
    display:flex;
    align-items: center;
    }
}

#vehicleIcon{
    margin: -12px;
}