/******************************************************************
	Typography
******************************************************************/

// Open Sans
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');

// Gotham-Bold Fonts
@import url('//db.onlinewebfonts.com/c/db33e70bc9dee9fa9ae9737ad83d77ba?family=Gotham');
// font-family: "Gotham";

body {
       font-family: "Gotham Medium";
}

@font-face {
       font-family: 'Gotham Medium';
       font-style: normal;
       font-weight: normal;
       src: local('Gotham Medium'), url('../fonts/GothamMedium.woff') format('woff');
}
       
@font-face {
       font-family: 'Gotham Book Italic';
       font-style: normal;
       font-weight: normal;
       src: local('Gotham Book Italic'), url('../fonts/GothamBookItalic.woff') format('woff');
}