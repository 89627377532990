#leftHandFiltersDesktop {
    background-color: $black;
    padding: 5px 5px 5px 5px;
    border-radius: 5px;
    margin: 45px 10px 0px 0px;

    .filterDesktop {
        background: rgba(0,0,0,0.5);
        display: flex;
        margin: 5px;
    }
    .showResultsTitle {
        text-decoration: underline;
    }

    .sliderTitle {
        margin: 25px 5px 5px 5px;
        color:white;
        display: flex;
        justify-content: space-between;
    }
    .typeHeader {
        margin: 5px;
        display: flex;
        justify-content: space-between;
        .typeSubHeaderTitle {
            margin-top: 5px;
        }
    }  
    
    .filterButtonsDesktop {
        color: white;
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        .greenButton {
            text-transform: capitalize;
            color: white;
            background-color: $black;
            border: 1px #fff solid;
            margin: 5px 10px 5px 10px;
        &:hover {
            color: white;
            background-color: $light-green;
            }
        }
        .active {
            color: white;
            background-color: $light-green;
        }
    }
    .availableDesktop {
        display: flex;
        justify-content: space-around;
        .availableTitle{
            margin: 30px 0px 20px 0px;
        }
        .availableCheckbox {
            margin: 20px 0px 20px 0px;
        }
    }

    .MuiSlider-marked { 
        width: 90% !important;
    }
    .MuiSlider-markLabel, MuiSlider-markLabelActive {
        &[style="left: 0%;"]{
        color: white;
        left: 3% !important;
        
        }
        &[style="left: 100%;"] {
            color: white;
            margin-right: 100px;
            left: 90% !important;
        }
    }
    .sliderValue {
        color: white;
        margin-top: 10px;
    }
}

#leftHandFiltersMobile {
    background-color: $lightslategrey;
    padding: 10px 20px 10px 10px;

     .sliderTitle {
        margin: 25px 20px 5px 5px;
        color:white;
        display: flex;
        justify-content: space-between;
    }

    .filterMobile {
        padding: 10px;
        background-color: #414a4e;
        display: flex;
        margin: -10px -10px 10px -10px;
        justify-content: space-around;
        width: 103%;
    }
    .showResultsTitle {
        text-decoration: underline;
    }
    
    .filterButtonsMobile {
        color: white;
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        .greenButton {
            text-transform: capitalize;
            color: white;
            background-color: $lightslategrey;
            border: 1px #fff solid;
            margin: 10px 30px 10px 30px;
        &:hover {
            color: white;
            background-color: $lightslategrey;
            }
        }
        .active {
            color: white;
            background-color: $light-green;
        }
    }
    .availableMobile {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        .availableTitle{
            margin: 10px 10px 0px 10px;
        }
        .availableCheckbox {
            margin-right: 10px;
        }
    }
    .filterDropdown {
        display: flex;
        justify-content: space-between;
        margin: 30px 20px 5px 10px;
        border-bottom: 1px solid white;
    }
    .MuiSlider-marked { 
       width: 90% !important;
       margin: 0px 0px 0px 10px
    }
    .MuiSlider-markLabel, MuiSlider-markLabelActive {
        &[style="left: 0%;"]{
        color: white;
        left: 3% !important;
        
        }
        &[style="left: 100%;"] {
            color: white;
            margin-right: 100px;
            left: 95% !important;
        }
    }
    .sliderValue {
        color: white;
        margin: 20px 0px 0px 70px;
    }
}

.leftHandMaster {
    display: flex;
    flex-direction: column;
}

.catalog_sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 38px;
    p {
        font-weight: 700;
        padding-right: 14px;
    }
    select {
        color: #fff;
        padding: 10px;
        font-weight: 700;
        background-color: #FFFFFF59;
    }
    svg {
        color: #fff;
    }
}

.mobile_catalog_sort {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        font-weight: 700;
        padding-right: 14px;
    }
    select {
        color: #fff;
        padding: 10px;
        font-weight: 700;
        background-color: #FFFFFF59;
    }
    svg {
        color: #fff;
    }
}

.sort_by_select {
    border: 1px solid #fff;
    color: #fff;
}

.vehicle_card_container {
    padding: 20px 38px 38px 38px;
    a {
        text-decoration: none;
    }
    .vehicle_card {
        background: #FFFFFF59;
        color: #fff;
        border: 3px solid #fff;
        backdrop-filter: blur(6px);
        font-weight: 700;
        .vehicle_card_media {
            width: auto;
            max-width: 90%;
            max-height: 200px;
            display: block;
            margin: 0 auto;
        }
        .MuiCardHeader-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        table {
            width: 100%;
            .card_detail_title {
                text-align: left;
            }
            .card_detail {
                font-weight: 400;
                text-align: right;
            }
        }
    }
    .vehicle_card_header {
        span {
            color: #fff;
            font-weight: 700;
            text-align: left;
        }
    }
}

