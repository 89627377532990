.catalog {
  .data_grid {
    border: "solid grey 4px";
    background: #ffffff;
    margin: 0;
    height: 625px;
    width: 88.5vw;
    font-size: 12px;
  }
}
#page {
  margin: 5vw;
  background-color: #f7f8f9;
  width: fit-content;
}

#PageTitle {
  p {
    font-size: 24px;
    font-family: "Gotham Medium";
  }

  p.page_sub_title {
    font-size: 16px;
    font-family: "Gotham Book Italic";
    color: #777777;
  }
}
#more_details_link {
  text-decoration: none;
  color: #007dbe;
  font-family: "Gotham Medium";
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
}
#more_details_link_mobile {
  text-decoration: none;
  color: #007DBE;
  font-family: "Gotham Medium";
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.dropdown-filters {
  margin: 100px 0px 30px;
  justify-content: space-between;
}

.page_class {
  width: 85vw;
}

.table {
  padding: 40px;
  background: #ffffff;
  width: 85vw;
  .data_grid {
    #data-grid-table {
      border: none;
    }
  }
}

div .MuiDataGrid-root {
  border: none;
  .MuiDataGrid-columnSeparator {
    display: none;
  }
}
#PageDisclaimer {
  .page_disclaimer {
    font-size: 12px;
    font-family: "Gotham Book Italic";
    margin: 30px;
  }
}
.more_details_link {
  text-decoration: none;
  color: #007dbe;
  font-weight: bold;
}
.dropdown_filter {
  background-color: #ffffff;
  width: 270px;
}
.dropdown-filters {
  display: flex;
  text-align: center;
  align-items: center;
  .table_sort {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 10px 0 0;
    p {
      font-family: "Gotham Medium";
      font-size: 16px;
    }
  }
  #postcode_input {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    text-align: center;
    justify-content: center;
    font-size: 30px;
  }
  .input_title {
    font-family: "Gotham Medium";
    font-size: 18px;
    text-align: left;
    margin-top: -15px;
    padding-bottom: 15px;
    width: fit-content;
  }
  .dropdown_filter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 200px;
    overflow: hidden;
    height: 48px;
    font-size: 14px;
    width: 100%;
    color: #777777;
    font-family: "Gotham Book Italic";
  }
  .location-tooltip {
    display: flex;
    align-items: flex-start;
    height: 100%;
    top: -2px;
    position: relative;
  }
  #update-button {
    background: #007dbe;
    color: white;
    font-size: 14px;
    font-weight: bold;
    height: 56px;
    width: 180px;
    margin-top: 18px;
    margin-left: -11px;
    text-transform: capitalize;
    box-shadow: none;
    border-radius: 0px;
  }
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  font-family: "Gotham Medium";
  font-size: 14px;
  text-transform: uppercase;
  color: #777777;
  padding: 0;
  margin: 0;
}

.MuiDataGrid-renderingZone {
  font-family: "Gotham Book Italic";
}

.total_found {
  font-family: "Gotham Book Italic" !important;
}

.status_container {
  height: 20px;
  font-size: 10px;
}
.total_found {
  font-size: 14px;
  font-family: "Gotham Book";
}
.vehicle_catalog {
  background-color: #f7f8f9;
}

.status_display{
  svg {
    padding: 10px;
    height: 0.8em;
  }
}

.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input
{
    display: flex;
    height: inherit;
    box-sizing: border-box;


    /* truncate text */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MuiInputBase-root.MuiOutlinedInput-root.dropdown_filter.MuiInputBase-formControl {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input,
.MuiSelect-iconOutlined {
  padding-left: 1em;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  width: 100%;
  padding-right: 20%;
}

.page_sub_title {
  display: flex;
  font-size: 16px;
  font-family: "Gotham Book Italic";
  color: #777777;
  justify-content: center;

}