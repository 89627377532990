.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-loader {
  display: inline-block;
  transform: translateZ(1px);
}
.circle-loader > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #d4cfcf;
}
.bounce > div {
  animation: bounce-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spin > div {
  animation: spin-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes spin-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes bounce-circle {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }
  0% {
    transform: scale(1, 1) translateY(0) rotateY(0deg);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: rotateY(1800deg) scale(1.05, 0.95) translateY(0);
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: rotateY(3600deg) scale(1, 1) translateY(0);
  }
}
