.catlog-page-title{
    text-align: left;
}
.catalog{
    text-align: left;
}
.page_title, .page_sub_title {
    color:black;
    font-size: 20px !important;
    text-align: left;
}
.page_disclaimer{
    color:black;
    font-size: 12px !important;
    text-align: left;
}
.dropdown-filters{
    margin: 50px 100px;
}