.no_data_text{
    font-family: "Gotham Book Italic";
    font-size: 16px;
    color: #777777;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)

}
#error {
    top: 90%;
}