/******************************************************************
	Variables
******************************************************************/

$blackgradient: linear-gradient(165.98deg, rgba(0, 0, 0, 0.5) 10.84%, rgba(51, 51, 51, 0.5) 92.87%);
$grey: #FFFFFF;
$light-green: #7cb554;
$black: #000;
$slategrey: #252526;
$lightslategrey: #3b3b3b;
$white: #fff;


$medium: 960px;
$large: 961px;
$small: 600px;

