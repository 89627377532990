.status_display{
    display: flex;
    .MuiTypography-body1 {
        font-family: 'Gotham Book Italic';
        font-size: 12px;
    }
}
.daysLeftFlex{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.MuiDataGrid-columnHeaderTitle{
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

.MuiDataGrid-headerContainer{
    height: 45px;
}
.red {
    color: red
}

#incentive_catalog{
    .MuiDataGrid-root {
        .MuiDataGrid-cell{
            overflow: unset;
            white-space: unset;
            line-height: unset !important;
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left;
            font-size: 12px;
        }
        .MuiDataGrid-sortIcon{
            display: none;
        }
        .MuiDataGrid-menuIcon{
            display: none;
        }
    }
}

.eligible-equipment-icon {
    &--container {
        margin: 0 auto;
        display:flex;
        align-items: center;
        max-height: inherit;
        box-sizing: border-box;
    }
    &__img {
        max-height: 3.5em;
        margin: 0 0.4em;

        &:only-child {
            padding-left: 2.4em;
        }
    }
}

@media(max-width:768px) {
    #incentive_catalog {
        .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable[data-field="incentive_focus"] {
            display:none;
        }

        .MuiDataGrid-root .MuiDataGrid-cell[data-field="incentive_focus"] {
            display:none;
        }
    }
}
